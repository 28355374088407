<template>
	<div style="padding: 30px;">
		   <p v-html="content"></p>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				content:""
			}
		},
		created(options){
			this.$req.get("/client/cms/article/get", {
				id:this.$route.query.id
			}).then(async res => {
				this.content=res.content
			})
		}
	}
</script>

<style>
</style>
